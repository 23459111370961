/**
 * Check Development Mode
 */
const isLive = true;

/**
 * Production URL
 */
// let DEVELOPMENT = "http://18.223.19.5";
let DEVELOPMENT = "http://localhost:3100";

/**
 * Development URL
 */
const Production_Url = "https://api.collegecat.com";

/**
 * Base URL that will be treated as global start-point.
 */
const BASE_URL = isLive ? Production_Url : DEVELOPMENT;

// const BASE_URL = DEVELOPMENT;

const URL = {
  LOGIN: `${BASE_URL}/auth/admin-login`,

  REGISTER: `${BASE_URL}/auth/register`,

  UPLOAD_IMAGE: `${BASE_URL}/S3/tempUpload`,

  ADD_TEMPLATE: `${BASE_URL}/template`,

  DELETE_TEMPLATE: `${BASE_URL}/template`,

  ADD_WORD_CREDITS: `${BASE_URL}/admin/issue-words-credits`,

  SINGLE_TEMPLATE_BY_ID: (templateID: string | string[] | undefined) =>
    `${BASE_URL}/template?templateID=${templateID}`,

  GET_TEMPLATES: `${BASE_URL}/admin/all-templates`,

  GET_ALL_USERS: `${BASE_URL}/admin/get-all-users`,

  GET_ALL_USERS_CSV: `${BASE_URL}/admin/get-users-csv`,

  EDIT_USER: `${BASE_URL}/admin/edit-user`,

  UPDATE_TEMPLATE: `${BASE_URL}/template`,

  DELETE_USER: `${BASE_URL}/admin/delete-user`,

  GET_ALL_HELP_REQUEST: `${BASE_URL}/admin/help-requests`,

  USER_HELP_REQUEST: `${BASE_URL}/admin/help-request`,

  SEND_HELP_REPLY: `${BASE_URL}/mail`,

  GET_ALL_PLANS: `${BASE_URL}/plans`,

  GET_ACTIVE_USERS: `${BASE_URL}/admin/active-users`,

  GET_TOTAL_MESSAGES_SENT: `${BASE_URL}/admin/messages-sent`,

  STRIPE_PLANS: `${BASE_URL}/stripe/products`,

  UPDATE_STRIPE_PLAN: (productID: string) =>
    `${BASE_URL}/stripe/updateProduct/${productID}`,

  UPDATE_STRIPE_PLAN_PRICE: (productID: string) =>
    `${BASE_URL}/stripe/updatePrice/${productID}`,

  GET_MOST_POPULAR_TEMPLATE: `${BASE_URL}/admin/most-popular-template`,

  GET_USERS_PER_MONTH: `${BASE_URL}/admin/users-per-month`,

  GET_CURRENT_STRIPE_PLAN: (subID: string) =>
    `${BASE_URL}/stripe/retrievePlan/${subID}`,

  GET_REPHRASER_DETAILS: `${BASE_URL}/admin/rephraser-tool-details`,

  EDIT_REPHRASER: `${BASE_URL}/admin/edit-rephraser-tool-details`,
};

export { BASE_URL, URL };
